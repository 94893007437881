
<div id="English" class="text">
    <div id="LanguageToggle"><b>EN</b> | DE</div>
    <p class="paragraph">Thank you for finding and wanting to return my stuff. I really appreciate you for that ;)</p>
    <p class="paragraph">Please choose your preferred way of getting in contact with me and I will arrange everything else.</p>
    <p id="Contact">
        Mail: hi@alexanderwallbaum.com <br>
        Phone: +49 1729161679
    </p>
</div>
