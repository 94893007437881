import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;

  constructor(private title:Title) { }

  ngOnInit(): void {
    this.title.setTitle("Alexander Wallbaum");
    this.myStyle = {
      'position': 'fixed',
      'width': '100%',
      'height': '100%',
      'z-index': -1,
      'top': 0,
      'left': '0',
      'right': 0,
      'bottom': 0,
    };
    this.myParams = { //blue
      particles: {
        number: { value: 20, density: { enable: false, value_area: 30000 } },
        color: { value: "#909090" },
        shape: {
          type: "circle",
          stroke: { width: 0, color: "#000000" },
        },
        opacity: {
          value: 1,
          random: false,
          anim: { enable: false, speed: 1, opacity_min: 0.5, sync: false }
        },
        size: {
          value: 3,
          random: true,
          anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 4,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: { enable: false, rotateX: 600, rotateY: 1200 }
        }
      },
      interactivity: {
        detect_on: "window",
        events: {
          onhover: { enable: false, mode: "bubble" },
          onclick: { enable: false, mode: "remove" },
          resize: true
        },
        modes: {
          grab: { distance: 400, line_linked: { opacity: 1 } },
          bubble: { distance: 120, size: 3.5, duration: 10, opacity: 8, speed: 3 },
          repulse: { distance: 220, duration: 0.8 },
          push: { particles_nb: 2 },
          remove: { particles_nb: 20 }
        }
      },
      } 
  }

}
