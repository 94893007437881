<div id="Background"></div>
<particles id="partikel" [params]="myParams" [style]="myStyle" [width]="width" [height]="height" *ngIf="false"></particles>    
<div id="TextBox">
    <p id="Description2">...website work in progress...</p>
    <p id="Name">Alexander Wallbaum</p>
    <p id="Description">DOP | 1AC | CC*</p>
</div>

    

